// Common resources shared by all applications
require('@fullstakk/fms-frontend-asset/dist/fontawesome/css/all.min.css')
require('@fullstakk/fms-frontend-asset/dist/css/component/block.scss')
require('@fullstakk/fms-frontend-asset/dist/css/component/button.scss')
require('@fullstakk/fms-frontend-asset/dist/css/component/form.scss')
require('@fullstakk/fms-frontend-asset/dist/css/layout/index.scss')
require('@fullstakk/fms-frontend-asset/dist/css/layout/fullscreen.scss')
require('@fullstakk/fms-frontend-asset/dist/css/layout/header-main-footer.scss')

// Specific resources for the application
require('./src/app/assets/css/layout/index.scss')
require('./src/app/assets/css/layout/home.scss')
